import React, {Component} from 'react';



class Main extends Component {
    constructor(props){
        super();


        if ("serial" in navigator) { // The Web Serial API is supported.

            navigator.serial.addEventListener('connect', (e) => {
                // Connect to `e.target` or add it to a list of available ports.
            });
            
            navigator.serial.addEventListener('disconnect', (e) => {
                // Remove `e.target` from the list of available ports.
            });
        }

        this.encoder = new TextEncoder();
        this.decoder = new TextDecoder();

        this.writer =[];
        this.reader = [];
    }
    
    componentDidMount(){

    }

  
    connect = async () => {

        if ('serial' in navigator) {
            try {
              const port = await navigator.serial.requestPort();
              await port.open({ baudRate: 57600  });
              this.reader = port.readable.getReader();
              this.writer = port.writable.getWriter();
            }
            catch (err) {
              console.error('There was an error opening the serial port:', err);
            }
          }
          else {
            console.error('The Web serial API doesn\'t seem to be enabled in your browser.');
          }
    }
    sendByte = async () => {

        
        let array  = [
                        0xF0, //START_SYSEX 
                        0x00, //sysex command
                        0x00, // send byte 
                        0xF7  //END_SYSEX 
                    ]


                    const dataArrayBuffer = this.encoder.encode(array);
                    return await this.writer.write(dataArrayBuffer);
    }

    render(){

        return(
            <div>
                <h1>Main</h1>
                <button onClick={this.connect}>Connect</button>
                <button onClick={this.sendByte}>Send</button>
            </div>
        );
    }
}

export default Main